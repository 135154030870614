import tryRequest, { GetRequestOptions } from './tryRequest'
import {
  PublicAllStatisticsParamsSchema,
  PublicAllStatisticsResponseSchema,
} from './types/public.all_statistics'

export default async function fetchAllStatistics(
  params: PublicAllStatisticsParamsSchema,
  options?: GetRequestOptions
) {
  return tryRequest<PublicAllStatisticsParamsSchema, PublicAllStatisticsResponseSchema>(
    '/public/all_statistics',
    params,
    {
      ...options,
      useGet: true,
    }
  )
}
